<template>
  <div class="MainItw">
    <!-- <div class="MainItw--rubrique">{{content.rubrique}}</div> -->
    <div class="MainItw--text" v-html="content.text"></div>
    <!-- <img class="MainItw--portrait" :class="scrollSequenceItw" :src="content.cover[0].url"> -->

    <img class="MainItw--portrait"  
    v-lazyloading
    :class="scrollSequenceItw"
    :src="content.cover[0].thumb" 
    :data-srcset="content.cover[0].srcset" 
    sizes="50vw">

  </div>
</template>

<script>
import LazyLoading from '@/directives/LazyLoading';

export default {
  name: 'MainItw',
  props: ['content', 'scrollSequence'],
  directives: {
    LazyLoading
  },
  computed:{
    scrollSequenceItw: function(){
      return this.scrollSequence + '-itw'
    },    
  },
  methods:{

  },

  created(){
    // console.log(this.content)
  }

}
</script>

<style lang="scss">
@import '@/css/variables';

.MainItw{

  min-height: calc(100vh - var(--marge-article));

  background-color: white;
  padding-top: var(--esp-med);
  padding-bottom: var(--esp-med);

  &--rubrique{
    position: relative;
    left: 20%;
    font-family: 'vd-reg';
    margin-top: var(--esp-med);
    margin-bottom: var(--esp-med);
    text-transform: uppercase;
    font-size: var(--article-font-paratexte);

    // background-color:red;

  }

  &--text{

    font-family: 'vd-reg';
    font-size: var(--article-font-text);
    width: calc( 50% - 2 * var(--esp-med));

    margin-left: var(--esp-med);

    padding-bottom: 10vw;

    line-height: 1.2;

    & p {

      & em{
        font-family: 'vd-ita';
      }

      & strong{
        letter-spacing: 0.3vw;
        text-decoration: underline;
      }  

    }

    & p:nth-child(odd){
      margin-left: calc( 2 * var(--esp-med));
      text-decoration: underline;
      color: var(--article-color);
      margin-bottom: calc( 0.2 * var(--article-font-text));
    }

    & p:nth-child(even){
      margin-bottom: var(--esp-med);

    }

  }

  &--portrait{
    max-width: calc( (100vw - var(--marge-article)) / 2.2  );
    height: calc( (100vh - var(--marge-article))  );
    object-fit: cover;
    pointer-events: none;

  }



}

.sequence1-itw{
  position: fixed;
  top: var(--marge-article);
  right:0;
}

// .sequence1-itw{
//   position: absolute;
//   top: 0;
//   right: 0;
// }

.sequence2-itw{
  position: fixed;
  top: var(--marge-article);
  right:0;
}

.sequence3-itw{
  position: absolute;
  bottom: 0;
  right: 0;
}


</style>