<template>
	<div class="CurrentTitle" v-if="whatConfig == 'mobile' ? false : true">
		<router-link 
		class="CurrentTitle--category"
		v-bind:to="'/' + $route.params.language + whatPathCategory"> 
		{{ $tools.getArticleCategory(category, this.$route.params.language).formatedCategory }}
		</router-link>&nbsp;—

		<router-link  
		class="CurrentTitle--rubrique"
		v-bind:to="'/' + $route.params.language + whatPathRubrique">
		{{rubrique}}
		</router-link>&nbsp;—

<!-- 		<router-link 
		class="CurrentTitle--title"
		v-bind:to="$route.path"
		@click="setStartArticle()">
			{{whatTitleClean}}
		</router-link> -->

		<div 
		class="CurrentTitle--title"
		@click="setStartArticle()">
			{{whatTitleClean}}
		</div>

	</div>
</template>

<script>

export default {
	name:'CurrentTitle',
	props: ['category', 'rubrique', 'title', 'whatConfig'],
	data: function(){
		return {
			titleClean: '',
		}
	},
	watch: {

	},
	computed: {
		whatTitleClean: function(){
			return this.title.replace('&shy;', '')
		},
		whatPathCategory:function(){
			return this.$tools.getArticleCategory(this.category, this.$route.params.language).uid
		},
		whatPathRubrique: function(){
			return this.$tools.getContentMenu(this.$route.params.language, this.$site.menu)[4].uid + '#' + this.rubrique
		},
		whatLinkTitle: function(){
			return window.location.href
		}
	},
	methods: {
		setStartArticle: function(){
			this.$emit('set-article-start')
		}

	},
	mounted(){

		// console.log(this.category, this.rubrique, this.title)

		// console.log(this.$route)
	}
}
	

</script>

<style lang="scss">
@import '@/css/variables';

.CurrentTitle{

  position: fixed;
  left: 50%;
  // bottom: calc(100vh - var(--marge-article) + var(--esp-med));
  // bottom: calc(100vh - #{$margeArticleWidth});
  color: var(--article-color);
  z-index: 9;

  font-size: var(--article-font-paratexte);
  height: calc( 1.2 * var(--article-font-paratexte));
  
  bottom: calc(100vh - var(--marge-article));

  & a {
    display: inline-block;
    color: var(--article-color);
  }

  & div {
    display: inline-block;
    color: var(--article-color);
    cursor: pointer
  }

}

</style>