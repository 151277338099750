<template>

  <transition name="fadeView" appear mode="out-in">

  <div>

    <CurrentTitle 
    :category="page.category"
    :rubrique="page.rubrique"
    :title="page.cartouche.title"
    :whatConfig="whatConfig"
    @set-article-start="setArticleStart">
    </CurrentTitle>

  <div class="Article" :class="whatConfig" @scroll="setScrollSequence" ref="article">

      <Cartouche 
      :content="page.cartouche"  
      :articleType="articleType" 
      @send-scroll-data="setCartouche"
      :style="{'top':CartoucheSticky}"
      ></Cartouche>

      <MainArticle 
      :articleType="articleType" 
      :content="page.main" 
      @send-scroll-data="setMainArticle"
      @send-mainvideo-height="setMainVideo"
      :style="{'top':MainArticleSticky}"
      :scrollSequence="scrollSequence"
      :scrollMainImg="scrollMainImg"
      ></MainArticle>

      <Redirect
      :content="page.redirect" 
      @send-redirect-height="setRedirect"
      ></Redirect>

      <Scrollbar :parent="'article'"></Scrollbar> 

  </div>

  </div>

  </transition>

</template>

<script>
import CurrentTitle from '@/components/article/CurrentTitle.vue'
import Cartouche from '@/components/article/Cartouche.vue'
import MainArticle from '@/components/article/MainArticle.vue'
import Redirect from '@/components/article/Redirect2.vue'

import Scrollbar from '@/components/scrollbar/Scrollbar.vue'

import clientConfigs from '@/mixins/clientConfigs.js'

// import smoothscroll from 'smoothscroll-polyfill';
import debounce from 'lodash/debounce';


export default {
  name: 'Article',
  mixins: [clientConfigs],
  components: {
    CurrentTitle,
    Cartouche,
    MainArticle,
    Redirect,

    Scrollbar
  },
  data: function () {
    return {
      state : this.$store.state,

      scrollState:{
        Cartouche: '',  // propriété reçu par emit 
        MainArticle: '', // propriété reçu par emit  
        Redirect: '', // propriété reçu par emit
      },
      scrollTop: '', // propriété calculé ici
      scrollSequence: 'sequence1', // propriété send

      scrollMainImg: 0,

      MainVideoHeight: 0,

      scrollType: ['Cartouche', 'MainArticle', 'Redirect'],

      scrollCoordinate: {
        sequence1: '',
        sequence2: '',
        sequence3: ''
      },

      windowHeight: '',
    }
  },

  watch: {
    scrollState: {
      handler(){
        let ArticleTypeComposants = this.scrollType
        let scrollTypeLength = (ArticleTypeComposants.length - 1)

        let receipt = 0 
        ArticleTypeComposants.forEach((element)=> {
          if (this.scrollState.[element]){
            receipt = receipt + 1
          }
        })

        if (receipt == scrollTypeLength){
          this.InitScrollCoordinate()
        }
      },
      deep: true
    },

    scrollTop: function(val){

      if ( val >= this.scrollCoordinate.sequence1 && val < this.scrollCoordinate.sequence2 ){
        this.scrollSequence = 'sequence1'
        this.scrollMainImg = 0

        //MainImg 
        // if ( val - this.scrollCoordinate.sequence2 > -60){
        //   this.scrollMainImg = val - this.scrollCoordinate.sequence2 + 60
        // }else {
        //   this.scrollMainImg = 0
        // }
      }

      if ( val >= this.scrollCoordinate.sequence2 && val < this.scrollCoordinate.sequence3 ){
        this.scrollSequence = 'sequence2'
        // this.scrollMainImg = val - this.scrollState.Cartouche.height + 60  // MainArticle.vue/MainImg
        this.scrollMainImg = val - this.scrollState.Cartouche.height  // MainArticle.vue/MainImg
      }

      if ( val > this.scrollCoordinate.sequence3 ){
        this.scrollSequence = 'sequence3'
        this.scrollMainImg = this.scrollState.MainArticle.height 
      }

    },
    scrollSequence: function(val){
      val
      // console.log(val)
    }
  },

  computed: {
    page: function () { return this.$store.state.[this.$route.params.language].[this.$route.name] },

    ColorArticle: function(){ 
      let artColor = window.getComputedStyle(document.documentElement).getPropertyValue('--first-color');
      let amateurColor = window.getComputedStyle(document.documentElement).getPropertyValue('--second-color');

      let artColorLighten = window.getComputedStyle(document.documentElement).getPropertyValue('--first-color-lighten');
      let amateurColorLighten = window.getComputedStyle(document.documentElement).getPropertyValue('--second-color-lighten');

      return [this.page.category === 'art' ? artColor : amateurColor, this.page.category === 'art' ? artColorLighten : amateurColorLighten]; 
    },
    articleType: function(){ 
      return this.page.articleType
    },
    whatConfig: function(){
      if ( this.ClientDevice != 'desktop' || this.ClientFormat == 'portrait'){
        return 'mobile'
      }else {
        return ''
      }
    },
    mainArticleToTop: function () {      
      return this.scrollState.Cartouche.height   // si plus grand que 100vh ?
    },
    CartoucheSticky: function(){
      let sticky = Math.floor(this.visibleWindowHeight() - this.scrollState.Cartouche.height)  // 'rectangle'
      if (sticky > 0){ sticky = 0 }   // 'normal' || 'carre'
      return sticky + "px";
    },
    MainArticleSticky: function(){
      return Math.floor(this.scrollState.Cartouche.height - this.scrollState.MainArticle.height + this.visibleWindowHeight() - this.mainArticleToTop)  + "px";
    }
  },
  methods: {
    InitScrollCoordinate: function(){

      this.scrollCoordinate.sequence1 = 0
      this.scrollCoordinate.sequence2 = Math.floor(this.scrollState.Cartouche.height + this.MainVideoHeight)
      this.scrollCoordinate.sequence3 = Math.floor(this.scrollState.MainArticle.height - this.visibleWindowHeight() + this.mainArticleToTop + this.MainVideoHeight)
    },
    setScrollSequence(e){
      this.scrollTop = e.target.scrollTop
    },

    visibleWindowHeight: function (){

      if ( this.ClientFormat == 'portrait'){
        let margeArticleRaw = window.getComputedStyle(document.documentElement).getPropertyValue('--menu-header-height');
        return window.innerHeight - margeArticleRaw.split('px')[0]
      }

      if ( this.ClientFormat == 'landscape'){
        let margeArticleRaw = window.getComputedStyle(document.documentElement).getPropertyValue('--marge-article');
        return window.innerHeight - (window.innerWidth * margeArticleRaw.split('vw')[0] / 100)
      }

    },
    setCartouche(dimension){
      this.scrollState.Cartouche = dimension
    },
    setMainArticle(dimension){
      this.scrollState.MainArticle = dimension
    },
    setRedirect(dimension){
      // console.log('article setRedirect')
      this.scrollState.Redirect = dimension
    },
    setMainVideo(dimension){
      // console.log('Article setMainVideo()', dimension)
      this.MainVideoHeight = dimension
    },
    setArticleStart(){
      this.$refs.article.scrollTo(0,0)
      this.scrollTop = 0
    },
    onResize(){
      // this.$el.scrollTo(0,this.$el.scrollTop + 1)
      // this.scrollTop = this.$el.scrollTop + 1

      this.$refs.article.scrollTo(0, this.$refs.article.scrollTop + 1)
      this.scrollTop = this.$refs.article.scrollTop + 1
      this.InitScrollCoordinate()

      // console.log('article onResize :', this.$el.scrollTop, 'scrollTop', this.scrollTop, 'scrollSequence', this.scrollSequence)
    }
  },
  mounted (){
    
    this.$client.isInit = true
    // console.log('article mounted')

    document.documentElement.style.setProperty('--article-color', this.ColorArticle[0])
    document.documentElement.style.setProperty('--article-color-lighten', this.ColorArticle[1])

    debounce
    // window.addEventListener('resize', this.onResize)
    this.onResizeDebounced = debounce(this.onResize, 100)
    window.addEventListener('resize', this.onResizeDebounced)

    // console.log('article category : ', this.page.category)
  },

  beforeDestroy(){
    // console.log('article beforeDestroy')

    window.removeEventListener('resize', this.onResizeDebounced)
  }
}
</script>


<style lang="scss">
@import '@/css/variables';

.Article{
  z-index: 2;


  left: var(--marge-article);
  top: var(--marge-article);

  position: fixed;

  width: calc(100vw - var(--marge-article));
  height: calc(100vh - var(--marge-article));

  overflow: scroll;

  background-color: white;

  scrollbar-width: none;
}



.Article.mobile{

  z-index: 2;
  position: fixed;

  // width: calc(100vw - var(--marge-page));  
  width: 100vw;  
  // height: calc(100vh - var(--menu-header-height));  
  height: calc(100% - var(--menu-header-height));

  // top: 13vw;
  top: var(--menu-header-height);
  // left: var(--marge-page);
  left: 0;
  overflow: scroll;
}


.Cartouche{
  // height: calc(100vh - #{$margeArticleWidth});
  // height: 145vh;  // test à supprimer
  // height: 100%;
  // top: $margeArticleWidth;
  // top: -200px;
  z-index: 1;
  

  // SEQUENCE 1 - 2 - 3
  position: -webkit-sticky;
  position: sticky;
  // top: 0; 
}

.MainArticle{
  // height: calc(100vh - #{$margeArticleWidth});
  position: relative;
  height: auto;
  background-color: white;
  
  z-index: 4;

  position: -webkit-sticky;
  position: sticky;
  // top: 0;  // -2008px; // dynamique ( 0 + cartouche.height - mainArticle.height )

}

.Redirect{
  // height: calc(100vh - #{$margeArticleWidth});
  position: relative;
  // height: 110vh;
  background-color: var(--article-color-lighten);

  z-index: 7;
}









</style>