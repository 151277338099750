<template>
<div class="Share">
	{{whatContentLang}} 
	<a :href="whatFbLink" target="_blank">Facebook</a>&nbsp;
	<!-- <a :href="whatMessengerLink" target="_blank">Messenger</a>&nbsp; -->
	<!-- <a :href="whatMessengerLink" target="_blank">Messenger</a>&nbsp; -->
	<a class="twitter-share-button" :href="whatTwitterLink" target="_blank">Twitter</a>&nbsp;
	<a :href="whatMailLink" >Mail</a>
</div>
</template>

<script>

export default {
	name:'Share',
	props: ['title', 'authors'],
	data: function(){
		return {

		}
	},
	watch: {

	},
	computed: {

		whatArticleTitleClean: function(){
			return this.title.replace('&shy;', '')
		},

		whatContentLang: function(){
			if (this.$route.params.language == 'fr'){
				return 'Partager l\'article'
			}else {
				return 'Share article'
			}
		},


		whatTwitterLink: function(){
			// return 'https://twitter.com/intent/tweet?text=Hello%20world'
			// return 'https://twitter.com/intent/tweet?text=Hello%20world'

			let twitterUrl = 'https://twitter.com/intent/tweet?'

			// let title

			let text = 'Revue Profane - ' + this.whatArticleTitleClean
			let url = window.location.href 

			return twitterUrl + 'text=' + text + '%0D%0A&url=' + url
		},

		whatFbLink: function(){
			return 'https://www.facebook.com/sharer/sharer.php?u=' + window.location.href 
		},

		whatMessengerLink: function(){
			return 'https://www.facebook.com/sharer/sharer.php?u=' + window.location.href
		},

		whatMailLink: function(){

			let siteTitle =  this.$store.getMetaContent(this.$site.meta)
			siteTitle

			let subject = 'Revue Profane - ' + this.whatArticleTitleClean
			// let authors = 'De ' + this.authors.text + ' et ' + this.authors.photo
			let main = 'Article : ' + this.whatArticleTitleClean
			// let body = siteTitle + '%0D%0A %0D%0A' + main + '%0D%0A %0D%0A' + window.location.href
			main
			
			let body

			if(this.$route.params.language == 'fr'){
				body = 'Voici un article de la revue Profane à consulter: ' + window.location.href
			}else {
				body = 'Here is an article to consult from Profane magazine: ' + window.location.href
			}

			// let body = 'Voici un article de la revue Profane à consulter: ' + window.location.href

			return 'mailto:?subject=' + subject + '&body=' + body
		}
	},
	methods: {


	},
	mounted(){




	}
}
	

</script>

<style lang="scss">
@import '@/css/variables';

.Share{
	color: white;
	font-size: var(--cartouche-font-paratexte);

	& a {
		display: inline-block;
		background-color: white;
		
		margin-top: 0.3vw;

		padding-top: calc(0.3 * var(--cartouche-font-paratexte));
		// padding-bottom: 0.2vw;
		padding-bottom: calc(0.18 * var(--cartouche-font-paratexte));
		// padding-left: 0.5vw;
		padding-left: calc(0.3 * var(--cartouche-font-paratexte));
		// padding-right: 0.5vw;
		padding-right: calc(0.3 * var(--cartouche-font-paratexte));

		color: var(--article-color);
	}
}
</style>