<template>

	<div
	class="RedirEvent"
	:class="whatConfig" >
		
		<router-link  class="RedirEvent--content" v-bind:to=" '/' + $route.params.language + '/decouvertes'">
			
      <template v-if="ClientWidth != 'mobileWidth'">
			<p class="RedirEvent--content--text">{{content.title}}</p>
			<p class="RedirEvent--content--text">{{content.title}}</p>
      <p class="RedirEvent--content--text">{{content.title}}</p>
      <p class="RedirEvent--content--text">{{content.title}}</p>
      </template>

      <template v-else>
      <p class="RedirEvent--content--text">{{content.title}}</p>
      <p class="RedirEvent--content--text">{{content.title}}</p>  
      </template>

		</router-link >

	</div> 

</template>

<script>
import LazyLoading from '@/directives/LazyLoading';
import clientConfigs from '@/mixins/clientConfigs.js'

export default {
  name: 'RedirEvent',
  mixins: [clientConfigs],
  props: ['content'],
  directives: {
		LazyLoading
  },
  computed: {
    whatConfig: function(){
      // if ( this.ClientDevice != 'desktop' || this.ClientWidth == 'mobileWidth'){
      if ( this.ClientDevice == 'mobile' || this.ClientWidth == 'mobileWidth'){
        return 'redirevent-mobile'
      }else {
        return ''
      }
    },
  },
  created(){
		// console.log(this.content)
  }
}
</script>

<style lang="scss">
@import '@/css/variables';

.RedirEvent{
	position: relative;
	width: 100%;
	height: 100%;	
	background-color: var(--third-color-lighten);

  overflow: hidden;
  cursor: pointer;

	&--content{
		display: flex;
		width: 250%;
		position: absolute;
		animation: slideshow-event 20s linear infinite;
    height: 100%;
    align-items: center;

    &--text{
      white-space: nowrap;
      padding-left: var(--esp-big);
      width: 100%;

      // background-color: red;
    }
	}
}

@keyframes slideshow-event {
  0%    { left: 0; }
  100%  { left: -125%; }
}

</style>