<template>
  <div class="MainTexte" :class="whatConfig">
    <div class="MainTexte--text-container">
      <!-- <div class="MainTexte--text-container--rubrique">{{content.rubrique}}</div> -->
      <div class="MainTexte--text-container--labeur" v-footnotes2 v-html="content.text"></div>
    </div>
    <div class="MainTexte--notes" :class="scrollSequenceFootnote" v-footnotes :data-notes="content.notes">
      <div class="MainTexte--notes--element" v-html="content.int_text"></div>
      <div 
      class="MainTexte--notes--element"
      v-for="(footnotes, index) in footnotesState" 
      :key="index">
        <!-- <p class="MainTexte--notes--element--note" v-if="supNoteIsVisible[index]">{{ footnotes }}</p> -->
        <p class="MainTexte--notes--element--note" v-if="supNoteIsVisible[index]" v-html="footnotes"></p>
      </div>
    </div>

  </div>
</template>

<script>
import clientConfigs from '@/mixins/clientConfigs.js'

export default {
  name: 'MainTexte',
  mixins: [clientConfigs],
  props: ['content', 'scrollSequence'],
  data: function () {
    return {
      footnotesState: [],
      footnotesDisplay: [],
    }
  },
  watch: {
    scrollSequence: function(val){
      val
      // console.log(val)
    }
  },
  components: {
  },
  directives: {

    footnotes:{
      bind: function(el, binding, vnode){
        let frag = document.createRange().createContextualFragment(el.dataset.notes);
        let footnotesNodes = frag.querySelectorAll('li')
        let footnotesArray = Array.prototype.slice.call(footnotesNodes)
        el.removeAttribute('data-notes')

        footnotesArray.forEach((element) => {

          let elementRaw = element.innerHTML.trim()
          let split = elementRaw.split(element.value + '.')
          let elementFormated = '<span class="NotesNumber">(' + element.value + '). </span>' + split[1] 
          let elementInjected = split.length > 1 ? elementFormated : elementRaw

          vnode.context.footnotesState.push(elementInjected)
        })
      }
    },

    footnotes2: {
      bind: function(el, binding, vnode){
        let supNotesNodes = el.querySelectorAll('.footnote')
        let supNotesArray = Array.prototype.slice.call(supNotesNodes)

        supNotesArray.forEach(() => {
          vnode.context.footnotesDisplay.push(false)
        })

        var options = {
          root: null,
          rootMargin: '0px',
          threshold: 1.0
        }

        var callback = function(entries, observer){
          entries.forEach(entry => {
            observer

            let indexSupNote = Number(entry.target.dataset.ref.split('-')[1]) - 1
            vnode.context.$set(vnode.context.footnotesDisplay, indexSupNote, entry.isIntersecting)

          })
        }

        var observer = new IntersectionObserver(callback, options);

        supNotesArray.forEach((element) => {
          observer.observe(element);
        })
      }
    }
  },
  
  computed: {
    whatConfig: function(){
      if (this.ClientFormat == 'portrait' && this.ClientWidth != 'mobile'){
        return 'mobile-maintexte'
      }else {
        return ''
      }
    },
    scrollSequenceFootnote: function(){
      return this.scrollSequence + '-footnote'
    },
    supNoteIsVisible: function(){
      return this.footnotesDisplay.map(function(item){
        return item
      })
    },
    footnotes: function(){
      return this.footnotesState
    }
  },

  methods: {

  },
  mounted(){
  }

}

</script>

<style lang="scss">
@import '@/css/variables';

.MainTexte{
  width: calc(100vw - var(--article-thumbs-width) - var(--marge-article));
  display: flex;
  justify-content: space-between;
  
  min-height: calc(100vh - var(--marge-article));

  &--text-container{
    
    overflow: hidden;
    margin-left: var(--esp-med);
    width: calc(var(--article-maintexte-width) - var(--esp-med));

    &--rubrique{
      position: relative;
      left: 20%;
      font-family: 'vd-reg';
      margin-top: var(--esp-med);
      margin-bottom: var(--esp-med);
      text-transform: uppercase;
      font-size: var(--article-font-paratexte);
    }

    &--labeur{
      padding-top: var(--esp-med);
      font-family: 'vd-reg';
      font-size: var(--article-font-text);
      line-height: 1.2; 

      padding-bottom: 15vw;

      /*ENTRETIEN*/

      & ul {
        text-decoration: underline;
        margin-left: 50px;

        // & li:first-child{
        //   text-indent: 0px;
        // }      
        // & li {
        //   text-indent: 0px;
        // }
        // & li:nth-child(odd){
        //   text-indent: 0px;
        // } 
        // & li:nth-child(even){
        //   text-indent: 0px;
        // }         
      }

      /*PARAGRAPHES*/

      & ol {
        & li:first-child{
          text-indent: 0px;
        }     
        & li {
          margin-bottom: var(--esp-med);
          text-indent: 50px;
        }
      }

      /*CHILDREN*/

      // & p{
      //   // margin-bottom: var(--esp-med);
      //   text-align: 1;
      //   text-indent: 50px;
      // }

      // & p:first-child{
      //   // text-indent: 100px;
      // }
      // 


      // & sup{
      //   // color: var(--article-color);
      //   font-size: var(--article-font-notes);
      //   position: absolute;
      //   // transform: translate(0.15vw, -0.5vw);
      //   text-indent: 0px;

      //   background-color: var(--article-color-lighten);
      //   height: var(--article-font-notes);

      //   color: black;
      //   transform: translate(calc(0.01 * var(--article-font-notes)), calc(-0.2 * var(--article-font-notes)));

      //   // vertical-align: bottom;
      //   // text-decoration: none;
      //   // font-size: var(--article-font-text);
      // }

      & sup{
        // color: var(--article-color);
        font-size: var(--article-font-notes);
        position: relative;
        // transform: translate(0.15vw, -0.5vw);
        text-indent: 0px;

        // background-color: var(--article-color-lighten);
        height: var(--article-font-notes);

        color: var(--article-color);
        // transform: translate(calc(0.01 * var(--article-font-notes)), calc(-0.2 * var(--article-font-notes)));

        // vertical-align: bottom; // old
        text-decoration: none;
        font-size: var(--article-font-text);
        // margin-right: 12px;
        // left: 0.08em;
        // margin-right: -0.15em;

        font-family: 'oli';
        font-size: calc( 0.6 * var(--article-font-text));
      }

      & sup:before{
        content: "(";
      }

      & sup:after{
        content: ")";
      }

      & em{
        font-family: 'vd-ita';
      }

      & strong{
        letter-spacing: 0.3vw;
        text-decoration: underline;
      }      
    }
  }

  &--notes{
    width: calc(0.25 * (100vw - var(--article-maintexte-marge) - var(--article-thumbs-width)) - var(--esp-med) );
    height: calc(100vh - var(--marge-article));

    & em{
      font-family: 'vd-ita';
    }

    &--element{
      font-family: 'vd-reg';
      line-height: 1.2;
      font-size: var(--article-font-notes);

      color: var(--article-color);

      margin-bottom: var(--esp-small);

      width: calc(100% - var(--esp-med));

      &:first-child{
        margin-top: var(--esp-med);
      }
    }
  }
}


.MainTexte--notes--element--note{
  pointer-events: auto;

  & a {
    color: var(--article-color);
  }
  
}

.MainTexte.mobile-maintexte{
  width: calc(100vw - var(--article-thumbs-width));
}



.sequence1-footnote{
  position : relative;
  pointer-events: none;
}

.sequence2-footnote{
  position: fixed;  
  top: var(--marge-article);
  right: var(--article-thumbs-width);
  width: calc(0.25 * (100vw - var(--article-maintexte-marge) - var(--article-thumbs-width)) - var(--esp-med) );
  pointer-events: none;
}

.sequence3-footnote{
  position: absolute;
  bottom: 0;

  right: var(--article-thumbs-width);
  width: calc(0.25 * (100vw - var(--article-maintexte-marge) - var(--article-thumbs-width)) - var(--esp-med) );
  pointer-events: none;
}


h1{
  text-align: center;
  text-decoration: underline;
  margin-bottom: var(--esp-med);
}


.MainTexte--text-container--labeur{

  & h1{ // titre 
    text-align: center;
    text-decoration: underline;
    margin-bottom: var(--esp-med);
  }

  & h2{
    text-indent: 50px;
  }

  & h3{ // break
    display: none;
  }

  & h4{ //
    margin-left: 50px;
    // margin-bottom: var(--esp-small);
    color: var(--article-color);

    & a {
      color: var(--article-color);
    }
  }

  & h5{

  }

  & hr{
    border:0;
    border-top:0px solid #555;
    height:0px;
    margin-bottom: var(--esp-med);
  }
}

.NotesNumber{
  font-family: 'oli';
  display: inline;
}


</style>