<template>

  <div class="images-wrapper" :style="{'height': containerHeightAfterCalcul + 400 + 'px'}">
    <div class="images-wrapper--sticky-container">
			<div class="images-wrapper--sticky-container--sticky">


				<div class="MainImg">

					<div ref="galleryContainer" class="MainImg--gallery-container" :class="[scrollSequenceViewer, whatConfig]" 
					:style="{width: whatGalleryWidth} + 'px'">

						<div class="MainImg--gallery-container--element"
						v-for="(element, index) in content" 
						:key="index">

							<transition name="fade-main-img" appear mode="out-in">
							<!-- v-if="!isClicked[index]" -->
							<img 
							class="MainImg--gallery-container--element--image"
							:data-index="index" 
							:src="element.thumb"
							:data-srcset="element.srcset" 
							sizes="35vw"
							v-observer
							@load="loadGalleryWidth($event, index)"
							key="visible"
							:style="{width: whatElementWidth[index]}">

<!-- 							<div v-else
							class="MainImg--gallery-container--element--legende" 
							:style="{width: whatElementWidth[index]}"
							key="invisible">
								<p v-html="element.legende"></p>
							</div> -->
							</transition>

							<transition name="fade-legendes" appear mode="out-in">
							<div v-if="isClicked[index]" class="MainImg--gallery-container--element--legendes">
								<p class="MainImg--gallery-container--element--legendes--paginate">{{index + 1}} / {{content.length}}</p>
								<p class="MainImg--gallery-container--element--legendes--content" 
								v-if="element.legende"
								v-html="element.legende">
								<!-- v-if="element.legende.length > 0" -->
								</p>
							</div>	
							</transition>				

<!-- 							<div class="MainImg--gallery-container--element--number">
								<p>({{index + 1}})</p>
							</div> -->

						</div>

<!-- 						<div class="MainImg--gallery-container--count" :class="scrollSequence">
							<p>/ ({{content.length}})</p>
						</div> -->
						
					</div>

				</div>


			</div>
		</div>
	</div>

</template>

<script>
import clientConfigs from '@/mixins/clientConfigs.js'
import LazyLoading from '@/directives/LazyLoading';

export default {
  name: 'MainImg',
  mixins: [clientConfigs],
  props: ['content', 'scrollSequence', 'scrollMainImg', 'eventClick', 'clientX', 'clientY'],
  data: function () {
		return {
			galleryScrollX: 0,
			galleryWidth: 0,

			tabObserver: [],
			tabObserverDisplay: [],

			DOM_gallery_container: '',

			loadedElements: [],
			tabElementsRatio: [],

			containerWidth: '',
			containerHeight: '',

			containerHeightAfterCalcul: '',


			tabLegendeHiding: [],
			maxCount: 20
		}
  },

	directives: {

		LazyLoading,

		observer:{
			inserted: function(el, binding, vnode){

				if (vnode.context.content[el.dataset.index].legende){
					vnode.context.tabObserverDisplay.push({hasLegende: true, clicked: false})
				} else {
					vnode.context.tabObserverDisplay.push({hasLegende: false, clicked: false})
				}

        var options = {
          root: null,
          rootMargin: '0px',
          threshold: 0.1
        }

        var callback = function(entries, observer){
          entries.forEach(entry => {
            observer
            let index = el.dataset.index
            vnode.context.$set(vnode.context.tabObserver, index, {element: el, display: entry.isIntersecting})
          })
        }
        var observer = new IntersectionObserver(callback, options);
        observer.observe(el)
			}
		}
  },

	watch: {
		clientX: function(val){
			val
			this.setCursor(val)
		},
		eventClick: function(val){
			val
			// this.clickListener(val)
			// this.tabLegendeHiding = []
		},
		scrollMainImg: function(val){
			
			this.DOM_gallery_container.scrollTo(val, 0)

			this.setCursor()

			// this.tabLegendeHiding.push(val)
			// if (this.tabLegendeHiding.length > this.maxCount){
			// 	this.tabObserverDisplay.forEach((element) => {
			// 		element.clicked = false
			// 	})
			// 	this.tabLegendeHiding = []
			// }
		},
		whatGalleryWidth: function(val){
			this.sendHeight(val)
		}
  },

	computed:{
		whatElementWidth: function(){
				return this.tabElementsRatio.map((item) => {
					return item * this.containerHeight + 'px'
				})
		},
		whatGalleryWidth: function(){
			let totalGalleryWidth = 0

			for( let i = 0; i < this.tabElementsRatio.length; i++){
				if (this.tabElementsRatio[i]){
					totalGalleryWidth = totalGalleryWidth + this.tabElementsRatio[i] * this.containerHeight
				}
			}

			let newHeight = totalGalleryWidth - this.containerWidth + this.containerHeight
			return newHeight
		},
    whatConfig: function(){
      if ( this.ClientDevice != 'desktop' || this.ClientFormat == 'portrait'){
        return 'main-img-mobile'
      }else {
        return ''
      }
    },
		isClicked: function() {
			return this.tabObserverDisplay.map(function(item) {
				return item.clicked;
			});
		},
		scrollSequenceViewer: function(){
			return this.scrollSequence + '-viewer'
		}
	},

	methods:{
		setCursor: function(){

			this.tabObserver.forEach((element, index) => {
				if (element.display == true){
					let x = this.clientX
					let y = this.clientY
					let l = element.element.getBoundingClientRect().left
					let w = l + element.element.getBoundingClientRect().width
					let t = element.element.getBoundingClientRect().top
					let h = t + element.element.getBoundingClientRect().height

					if (x >= l && x <= w && y >= t && y <= h){

						// if (this.tabObserverDisplay[index].hasLegende){
						// 	this.$el.parentNode.parentNode.style.cursor = "pointer"
						// }else {
						// 	this.$el.parentNode.parentNode.style.cursor = ""
						// }
						// console.log('setcursor')
						this.tabObserverDisplay[index].clicked = true


						// if (this.tabObserverDisplay[index].hasLegende){
						// 	this.$el.parentNode.parentNode.style.cursor = "pointer"

						// 	if ( this.tabObserverDisplay[index].hasLegende ){
						// 		this.tabObserverDisplay[index].clicked = true
						// 	}



						// }else {
						// 	this.$el.parentNode.parentNode.style.cursor = ""
						// }

					}else {
						this.tabObserverDisplay[index].clicked = false
					}
				}
			})
		},

		// clickListener: function(val){

		// 	this.tabObserver.forEach((element, index) => {

		// 		// this.tabObserverDisplay[index].clicked = false

		// 		if (element.display == true){
		// 			let x = val.clientX
		// 			let y = val.clientY
		// 			let l = element.element.getBoundingClientRect().left
		// 			let w = l + element.element.getBoundingClientRect().width
		// 			let t = element.element.getBoundingClientRect().top
		// 			let h = t + element.element.getBoundingClientRect().height

		// 			// if (x >= l && x <= w && y >= t && y <= h && this.tabObserverDisplay[index].hasLegende){
		// 			// 	this.tabObserverDisplay[index].clicked = !this.tabObserverDisplay[index].clicked
		// 			// }

		// 			if (x >= l && x <= w && y >= t && y <= h){
		// 				this.tabObserverDisplay[index].clicked = !this.tabObserverDisplay[index].clicked
		// 			}
		// 		}
		// 	})
		// },
		loadGalleryWidth: function(e, index){

			if( this.loadedElements.length < this.content.length ){				

				if ( e.target.dataset.srcset ){ // si il existe un attribut data-srcset
					let target = e.target
					let srcset = e.target.dataset.srcset

					e.target.removeAttribute('data-srcset')
					target.srcset = srcset
				}

				let ratio =  e.target.scrollWidth / this.$refs.galleryContainer.getBoundingClientRect().height 
				this.$set(this.tabElementsRatio, index, ratio)

				this.loadedElements.push(index)

			}else {

				if ( e.target.dataset.srcset ){
					let target = e.target
					let srcset = e.target.dataset.srcset

					e.target.removeAttribute('data-srcset')
					target.srcset = srcset
				}
			}
		},
		sendHeight: function(height){
			this.$emit('send-height', height)
			this.containerHeightAfterCalcul = height
		},
  },

  mounted () {
		this.DOM_gallery_container = this.$el.querySelector('.MainImg--gallery-container')


		/* securité - bug */
		setTimeout(() => { 
			console.log('security settimeout')
			this.containerWidth = this.$refs.galleryContainer.getBoundingClientRect().width
			this.containerHeight = this.$refs.galleryContainer.getBoundingClientRect().height
		}, 2000);

    this.$tools.setResizeObserver(this.$refs.galleryContainer, () => {
			console.log('main img resizeObserver')
			this.containerWidth = this.$refs.galleryContainer.getBoundingClientRect().width
			this.containerHeight = this.$refs.galleryContainer.getBoundingClientRect().height
    })
  }
}

</script>

<style lang="scss">
@import '@/css/variables';

.fade-main-img-enter-active, .fade-main-img-leave-active {
  transition: opacity .5s;
}
.fade-main-img-enter, .fade-main-img-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.fade-legendes-enter-active, .fade-legendes-leave-active {
  transition: transform .5s;
}
.fade-legendes-enter, .fade-legendes-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateY(100%);

}

.images-wrapper{
  display: flex;
  flex-direction: column;
  // width: 100vw;

  &--sticky-container{
    flex-grow:1;
    flex-shrink: 1;
    flex-basis: auto;

    &--sticky{
      position: sticky;
      top: 0;
      height: calc(100vh - var(--marge-article));
    }
  }
}

.MainImg{	

	&--gallery-container{

		background-color: var(--article-color-lighten);

		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
 
		position: relative;
		top: 0;
		
		overflow-y: hidden;
		overflow-x: scroll;

		width: calc(100vw - var(--marge-article));
		max-width: 800%;

		pointer-events: none;

		&--element{
			height: calc(100vh - var(--marge-article));			
			position: relative;
			display: flex;

			&--image{
				position: relative;
				height: 100%;
				object-fit: cover;
			}

			&--legende{
				position: relative;
				background-color: var(--article-color-lighten);
				height: 100%;
				text-align: center;

				& p:first-child{
					text-align: center;
					margin-bottom: var(--esp-med);

					// font-family: 'vd-reg';
					// font-size: var(--article-font-notes);
				}

				& p{
					font-family: 'vd-reg';
					font-size: var(--article-font-notes);
					margin-top: calc(0.3 * var(--article-font-notes));
				}
			}

			&--number{
				position: absolute;
				margin-top: var(--esp-med);
				margin-left: var(--esp-med);
				font-size: 3vw;

				font-size: 35px;

				mix-blend-mode: exclusion;

				& p {
					// color: white;
					color: var(--article-color-lighten);
				}

			}
		}
	}
}

.MainImg--gallery-container.main-img-mobile{
	width: 100vw;
}

.MainImg--gallery-container--count{
	mix-blend-mode: exclusion;
	// mix-blend-mode: difference;
	
	& p {
		// color: white;
		color: var(--article-color-lighten);

		font-size: 3vw;
		font-size: 35px;
	}
}



.MainImg--gallery-container--element--legendes{
	position: absolute;
	width: 100%;
	// height: 10vw;
	bottom: 0;
	background-color: var(--article-color-lighten);
	font-family: 'vd-reg';
	text-align: center;
	padding-top: var(--esp-small);
	padding-bottom:var(--esp-small);
	line-height: 1.2;

	&--paginate{
		// line-height: 1.5;
	}

	&--content{
		padding-top: 8px;
	}

}


.MainImg--gallery-container--count.sequence1{
	position: absolute;
	top: 0;
	right: 0;

	margin-top: var(--esp-med);
	margin-right: var(--esp-med);

	font-size: 3vw;

	opacity: 0;
}

.MainImg--gallery-container--count.sequence2{
	position: fixed;
	top: var(--marge-article);
	right: 0;

	margin-top: var(--esp-med);
	margin-right: var(--esp-med);

	font-size: 3vw;
}

.MainImg--gallery-container--count.sequence3{
	position: fixed;
	top: var(--marge-article);
	right: 0;

	margin-top: var(--esp-med);
	margin-right: var(--esp-med);

	font-size: 3vw;
}

.MainImg--gallery-container--element--legendes--content{
  & em{
    font-family: 'vd-ita';
  }
}

</style>