<template>
	<div class="Cartouche" :style="{height: whatHeight + 'px'}">
	<div class="Cartouche--text-container">
		<div>
			<div class="Cartouche--text-container--title" v-html="content.title"><!--  {{ content.title }}  --></div>
			<Share :title="content.title" :texte="content.text" :authors="content.authors" ></Share>
			<div class="Cartouche--text-container--main" v-html="content.text"><!-- {{ content.text }} --></div>
			<div class="Cartouche--text-container--authors">

				<div>
					<!-- <template v-if="content.authors.text.length > 0"> -->
					<template v-if="content.authors.text">
						<div v-if="$route.params.language == 'fr'">Texte&nbsp;: {{content.authors.text}}</div>
						<div v-if="$route.params.language == 'en'">Words&nbsp;: {{content.authors.text}}</div>
					</template>
					<!-- <template v-if="content.authors.photo.length > 0"> -->
					<template v-if="content.authors.photo">
						<div v-if="$route.params.language == 'fr'">Photographies&nbsp;: {{content.authors.photo}}</div>
						<div v-if="$route.params.language == 'en'">Photos&nbsp;: {{content.authors.photo}}</div>
					</template>

					<!-- <template v-if="content.authors.more.length > 0"> -->
					<template v-if="content.authors.more">
						<template v-for="(author, index) in content.authors.more">

							<template v-if="content.authors.more.length - 1 == index">
								<div :key="index" class="authors-flex" v-html="author.categorie + '&nbsp;: ' + author.name"></div>
							</template>
							<template v-else>
								<div :key="index" class="authors-flex" v-html="author.categorie + '&nbsp;: ' + author.name"></div>
							</template>

						</template>
					</template> 

				</div>

			</div>

			<div class="Cartouche--text-container--date">{{whatContentDate}}</div>

		</div>
	</div>

	<img class="Cartouche--img-container"  
	v-lazyloading
	v-if="!(articleType == 'itw')"
	:alt="content.title" 
	:src="content.covers.portrait[0].thumb" 
	:data-srcset="content.covers.portrait[0].srcset" 
	sizes="40vw">

	</div>
</template>

<script>
import Share from '@/components/article/cartouche/Share.vue'

import LazyLoading from '@/directives/LazyLoading';
import clientConfigs from '@/mixins/clientConfigs.js'
import debounce from 'lodash/debounce';

export default {
  name: 'Cartouche',
  mixins: [clientConfigs],
  props: ['content', 'articleType'],
  components: {
		Share
  },
  data: function(){
		return {
			whatInitHeightIsSet: false,
			whatHeight: '',

			gabaritHeight: false,
			manualHeight: false
		}
  },

  directives: {
		LazyLoading
  },

  computed: {
    ClientRatio: function(){ return this.$client.state.ratio},

    whatContentDate: function(){
			if (this.$route.params.language == 'fr'){
				return 'Publié dans Profane n°' + this.content.isProfaneNumber + ', ' + this.content.isSaison
			}else {
				return 'Published in Profane n°' + this.content.isProfaneNumber + ', ' + this.content.isSaison
			} 
		}
  },

  methods: {
		sendScrollData(){
			// console.log('sendScrollData', this.$el.getBoundingClientRect().height)
			this.$emit('send-scroll-data', this.$el.getBoundingClientRect())
		},

    initHeight: function(){
		
			if ( this.ClientFormat == 'portrait' && this.ClientRatio < 0.95 && !(this.manualHeight)){  // portrait
				// console.log('INIT PORTRAIT EXTREME')
				this.$el.style.height = this.ClientRatio * window.innerWidth + 'px'
				// this.whatHeight = this.ClientRatio * window.innerWidth + 'px'

			}

			// if (this.ClientFormat == 'landscape' && this.ClientRatio > 1.7) { // landscape
			// 	console.log('INIT LANDSCAPE EXTREME')
			// 	this.$el.style.height = ((1 + this.ClientRatio - 1.7) * window.innerHeight) + 'px'
			// }

    },
		setHeight(){ // ajuster height en fonction du contenu (après initHeight)
			let DOM_container = this.$el.children[0]
			let DOM_text = DOM_container.children[0]

			let DOM_container_height = DOM_container.getBoundingClientRect().height
			let DOM_text_height = DOM_text.getBoundingClientRect().height

			if (DOM_text_height + 40 > DOM_container_height){
				this.$el.style.height = this.$el.getBoundingClientRect().height + 40 + 'px'
				// this.$el.style.height = DOM_text_height + 50 + 'px'
				// this.whatHeight = this.whatHeight + 40
				
				this.manualHeight = true

				this.sendScrollData()
			}	
		},
		onResize(){

			// Resize Unobserve pour performance ?
			this.$tools.toggleResizeObserver(this.observer, this.$el, false)
			
			this.$el.style.height = ''
			this.manualHeight = false
			this.gabaritHeight = false

			this.initHeight()
			this.setHeight()
			this.sendScrollData()

			// Set Resize Observer
			this.$tools.toggleResizeObserver(this.observer, this.$el, true)
		}
  },
	created(){
		let contentMore = this.content.authors.more ? true : false
		contentMore
	},
  mounted () {

    this.observer = this.$tools.setResizeObserverWithReturn(this.$el, () => {
			// console.log('cartouche observer')
			this.initHeight()
			this.setHeight()
			this.sendScrollData()
    })

    this.onResizeDebounced = debounce(this.onResize, 100)
    window.addEventListener('resize', this.onResizeDebounced)

  }
}

</script>

<style lang="scss">
@import '@/css/variables';

.Cartouche{
	width: 100%;
	height: 100%;
	background-color: var(--article-color);
	display: flex;
	justify-content: space-between;

	&--text-container{
		margin: var(--esp-med);

		// width: 50%;
		max-width: 50%;

 
		&--title{
			color: white;
			font-size: var(--cartouche-font-title);
			line-height: 0.9;
			word-break: break-word;

		}
		&--share{
			color: white;

		}
		&--main{
			margin-top: 1.5vw;
			color: white;
			font-family: 'vd-reg';
			font-size: var(--cartouche-font-text);
			line-height: 1.1;

			// text-decoration: underline solid white 2px;
			// text-underline-offset: 5px;

			text-decoration: underline solid white 0.15vw;
			text-underline-offset: 0.3vw;

			& em {
				font-family: 'vd-ita';
			}

		}
		&--authors{
			color: white;
			font-family: 'vd-reg';
			font-size: var(--cartouche-font-paratexte);
			line-height: 1.2;

			// position: absolute;
			// bottom: var(--esp-med);

			// display: flex;
			// flex-wrap: wrap;
			// justify-content: space-between;

			margin-top: 2vw;
			position: relative;
			justify-content: left;

			& em{
				// margin-left: var(--esp-med);
			}

			& div{
				// margin-left: var(--esp-med);
				margin-bottom: calc( 0.5 * var(--esp-small));
			}

			& div:first-child{
				// margin-left: 0;
			}
		}

		&--date{
			font-size: var(--cartouche-font-paratexte);
			color: white;
			font-family: 'vd-reg';
			line-height: 1.2;
		}
	}

	&--img-container{

		height: 100%; // test à supprimer
		max-width: 40%;
		object-fit: cover;
	}
}

.authors-flex{
	& em {
		font-family: 'vd-ita';
	}
}




</style>
