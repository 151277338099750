<template>
  <transition name="fade" appear mode="out-in">

  <div class="MainViewerImage">

    <img class="MainViewerImage--element" 
    v-viewerloading
    :src="thumb"
    :data-srcset="content" 
    :class="orientation"
    sizes="60vw">

  </div>

  </transition>
</template>

<script>

export default {
  name: 'MainViewerImage',
  props: ['content', 'orientation', 'thumb'],
  data: function(){
    return {
      isVisible: true
    }
  },
  directives: {
    viewerloading: {

      // update(el, binding, vnode){
      update(el, binding, vnode){
        el, binding, vnode

        let newThumb = new Image;
        newThumb.src = vnode.context.thumb
        newThumb.onload = function() {

          let orientation = newThumb.height > newThumb.width ? 'portrait' : 'landscape'
          let oldOrientation = el.style.width == "auto" ? 'portrait' : 'landscape'

          if (orientation != oldOrientation){
            el.srcset = ''
            el.src = ''
          }

          if (orientation == 'portrait'){
            el.style.width = "auto";
            el.style.height = "100%";
            el.style.objectFit = "cover"
          }

          if (orientation == 'landscape'){
            el.style.width = "100%";
            el.style.height = "auto";
            el.style.objectFit = "fill"
          }

          // INSERTION THUMB DANS  LE DOM
          el.src = vnode.context.thumb


          // INSERTION SRCSET DANS  LE DOM
          let newImg = new Image;
          newImg.srcset = el.dataset.srcset
          newImg.onload = function() {
            el.srcset = newImg.srcset;
          }
        }

      }
    }
  },
  watch: {
  },
  computed:{
  },
  methods: {
  },
  created(){
  },
  mounted() {
  },
  beforeDestroy(){
  }
}
</script>

<style lang="scss">

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.MainViewerImage{

  width: 75%;
  height: 100%;

  &--element{
    max-width: 100%;
    max-height: 100%;

  }

}



</style>
