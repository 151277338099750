<template>
  <div class="Redirect">

	<div class="Redirect--shop">

<!-- 		<div class="Redirect--shop--container">
			<router-link class="Redirect--shop--container--link" :class="whatConfig"
			v-bind:to=" '/' + $route.params.language + '/boutique/' + content.shop.uid"> 
				<p>Retrouvez cet acticle dans Profane n°{{content.shop.isProfaneNumber}}</p>
			</router-link>
			<img class="Redirect--shop--container--img" :class="whatConfig"
			:src="content.shop.cover.thumb"
			:data-srcset="content.shop.cover.srcset" 
			sizes="30vw"
			v-lazyloading>
		</div> -->

		<router-link class="Redirect--shop--container"
		v-bind:to=" '/' + $route.params.language + '/boutique/' + content.shop.uid">
			<div class="Redirect--shop--container--link" :class="whatConfig"> 
				<p v-if="$route.params.language == 'fr'">Retrouvez cet article dans Profane n°{{content.shop.isProfaneNumber}}</p>
				<p v-if="$route.params.language == 'en'">Enjoy this article in Profane n°{{content.shop.isProfaneNumber}}</p>
			</div>

			<div class="Redirect--shop--container--img" :class="whatConfig">
				<img class="Redirect--shop--container--img--img"
				:src="content.shop.cover.thumb"
				:data-srcset="content.shop.cover.srcset" 
				sizes="30vw"
				v-lazyloading>
			</div>
		</router-link>


	</div>

	<div class="Redirect--articles">
		<div class="Redirect--articles--title"><p>{{whatContentLangTitle}}</p></div>
		<div class="Redirect--articles--container">
			<RedirCard :content="content.articles" :widthElement="widthElement" :maxElements="maxElements"></RedirCard>
		</div>
	</div>


	<div class="Redirect--newsletter"> 
		<template v-if="!newsletterClicked">
			<div @click="newsletterClicked = !newsletterClicked"><p>{{whatContentLangNews}}</p></div>
		</template>
		<template v-if="newsletterClicked">
			<Newsletter></Newsletter>
		</template>
	</div>

	<!-- <template v-if="ClientWidth != 'mobileWidth'"> -->
	<template>

	<div class="Redirect--event" v-if="content.event.isActive">
		<RedirEvent :content="content.event.content"></RedirEvent>
	</div>

	</template>

  </div>
</template>

<script>
// import RedirArticle from '@/components/article/redirect/RedirArticle.vue'
// import RedirShop from '@/components/article/redirect/RedirShop.vue'
import RedirEvent from '@/components/article/redirect/RedirEvent.vue'
import Newsletter from '@/components/Newsletter.vue'

import RedirCard from '@/components/article/redirect/RedirCard.vue'

import clientConfigs from '@/mixins/clientConfigs.js'
import LazyLoading from '@/directives/LazyLoading';


export default {
  name: 'Redirect',
  mixins: [clientConfigs],
  props: ['content'],
	data: function(){
		return {
			newsletterClicked: false
		}
  },
	components:{
		// RedirArticle,
		// RedirShop,
		RedirEvent,
		Newsletter,
		RedirCard
  },
  directives: {
		LazyLoading
  },
  computed: {
		whatContentLangTitle: function(){
			if (this.$route.params.language == 'fr'){
				return 'Vous aimerez aussi'
			}else {
				return 'You might also like'
			}
		}, 
		whatContentLangNews: function(){
			if (this.$route.params.language == 'fr'){
				return 'Inscrivez-vous à la newsletter'
			}else {
				return 'Subscribe to the newsletter'
			}
		}, 

    whatConfig: function(){
      // if ( this.ClientDevice != 'desktop' || this.ClientWidth == 'mobileWidth'){
      if ( this.ClientDevice == 'mobile' || this.ClientWidth == 'mobileWidth'){
        return 'redirect-mobile'
      }else {
        return ''
      }
    },

    widthElement: function(){

      let columns
      if ( this.ClientDevice == 'mobile' ){
        columns = 'two-columns'
      }
      if ( this.ClientDevice == 'tablet' ){
        columns = 'five-columns'
      }
      
      if ( this.ClientDevice == 'desktop' ){

          if (this.ClientFormat == 'portrait'){

            if (this.ClientWidth == 'mobileWidth'){
              columns = 'two-columns'

            }else if ( this.ClientWidth == 'tabletWidth'){
							columns = 'four-columns'

            }else {
              columns = 'five-columns'
            }
          }

          if (this.ClientFormat == 'landscape'){

            columns = 'five-columns'

            if (this.ClientWidth == 'desktopBig' || this.ClientWidth == 'desktopBigger'){
              columns = 'five-columns'
            }
            if (this.ClientWidth == 'desktopBiggest' || this.ClientWidth == 'desktopMax'){
              columns = 'six-columns'
            }
          }
      }

      return columns
    },
    maxElements: function(){

      let maxElement
      if ( this.ClientDevice == 'mobile' ){
        maxElement = 2
      }
      if ( this.ClientDevice == 'tablet' ){
        maxElement = 5
      }
      
      if ( this.ClientDevice == 'desktop' ){

          if (this.ClientFormat == 'portrait'){

            if (this.ClientWidth == 'mobileWidth'){
              maxElement = 2

            }else if ( this.ClientWidth == 'tabletWidth'){
							maxElement = 4

            }else {
              maxElement = 5
            }
          }

          if (this.ClientFormat == 'landscape'){

            maxElement = 5

            if (this.ClientWidth == 'desktopBig' || this.ClientWidth == 'desktopBigger'){
              maxElement = 5
            }
            if (this.ClientWidth == 'desktopBiggest' || this.ClientWidth == 'desktopMax'){
              maxElement = 6
            }
          }
      }

      return maxElement
    },

  },
  methods: {
		sendScrollData(){
			this.$emit('send-scroll-data', this.$el.getBoundingClientRect())
		},
  },
  mounted() {
    this.$tools.setResizeObserver(this.$el, () => {
      this.sendScrollData()
    })

    // console.log(this.content)
  }  
}
</script>

<style lang="scss">
@import '@/css/variables';

.Redirect{
	// position: relative;
	width: 100%;
	// height: var(--redirect-height);
	background-color: white;
}


.Redirect--shop{

	background-color: black;

	// height: 10vw;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&--container{
		color: white;
		// font-size: 6vw;
		font-size: var(--redirect2-font-title);
		line-height: 1;
		text-align: center;

		// margin-top: var(--esp-big);
		// margin-bottom: var(--esp-big);

		& a {
			color: white;
			text-align: center;
		}
	}
}

.Redirect--shop--container{
		margin-top: 0;
		margin-bottom: 0;
		display: flex;

		&--link{
			height: calc(2 * var(--redirect2-font-title) + 2 * var(--esp-big));

			overflow: hidden;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			&.redirect-mobile{
				height: calc(3 * var(--redirect2-font-title) + 2 * var(--esp-big));
				width: 70%; 
			}

			& p {
				margin: var(--esp-big);
				color: white;
			}
		}

		&--img{
			height: calc(2 * var(--redirect2-font-title) + 2 * var(--esp-big));
			width: calc(1.4 * (2 * var(--redirect2-font-title) + 2 * var(--esp-big)));
			overflow: hidden;

			&.redirect-mobile{

				height: calc(3 * var(--redirect2-font-title) + 2 * var(--esp-big));
				width: calc(3 * var(--redirect2-font-title) + 2 * var(--esp-big));


			}

			&--img{
				width: 100%;
				height: 100%;
				object-fit: cover;
				
				transition: transform .35s ease;

				&:hover{
					transform: scale(1.1);
				}
			}
		}
}

.Redirect--articles{
	background-color: var(--article-color-lighten);

	
	&--title{
		height: calc(1.2 * var(--redirect-title-height));
		font-size: var(--redirect-font-title);
		text-align: center;
		line-height: 0.9;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // font-size: 6vw;
    // height: 10vw;

	}

	&--container{

		// margin-left: var(--esp-med);
		// margin-right: var(--esp-med);



	}
}



.Redirect--newsletter{
	position: relative;
	height: calc(1.2 * var(--redirect-title-height));
	font-size: var(--redirect-font-title);
	width: 100%;
	overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: white;

  cursor: pointer;
}

// .Redirect--event{
// 	position: relative;
// 	width: 100%;
// 	// height: calc( 2 * var(--redirect-title-height));
// 	// font-size: calc( 2 * var(--redirect-font-title));
// 	// 
// 	height: 20vw;
// 	font-size: 20vw;
// }


// .Redirect--event{
// 	position: relative;
// 	width: 100%;
// 	height: 10vw;
// 	// font-size: 6vw;
// 	font-size: var(--redirect2-font-title);

// }

.Redirect--event{
	position: relative;
	width: 100%;
	height: calc(1.2 * var(--redirect-title-height));
	font-size: var(--redirect-font-title);

}


</style>