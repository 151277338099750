<template>

	<div class="RedirCard" :class="whatConfig" >

<!--   <router-link 
    v-bind:to=" '/' + $route.params.language + '/' + article.uid " 
    class="RedirCard--element" 
    v-for="article in content" 
    :key="article.title"
    :class="widthElement"
    >
    <div class="RedirCard--element--cover">
      <img class="RedirCard--element--cover--img" 
      v-lazyloading
      :alt="article.subtitle_cover" 
      :src="article.cover.thumb" 
      :data-srcset="article.cover.srcset" 
      sizes="25vw">
    </div>

    <div class="RedirCard--element--title"> {{ article.title }} </div>
    <div class="RedirCard--element--text" v-html="article.subtitle_cover"></div>
  </router-link> -->


<!--   <div v-for="index in 5"  :key="index">
    {{index}}
    {{content[index - 1]}}
  </div> -->

  <router-link 
    v-bind:to=" '/' + $route.params.language + '/' + content[index - 1].uid " 
    class="RedirCard--element" 
    v-for="index in maxElements" 
    :key="index"
    :class="widthElement"
    >
    <div class="RedirCard--element--cover">
      <img class="RedirCard--element--cover--img" 
      v-lazyloading
      :alt="content[index - 1].subtitle_cover" 
      :src="content[index - 1].cover.thumb" 
      :data-srcset="content[index - 1].cover.srcset" 
      sizes="25vw">
    </div>

    <div class="RedirCard--element--title" v-html="content[index - 1].title"></div>
    <div class="RedirCard--element--text" v-html="content[index - 1].subtitle_cover"></div>
  </router-link>

	</div> 

</template>

<script>
import LazyLoading from '@/directives/LazyLoading';
import clientConfigs from '@/mixins/clientConfigs.js'

export default {
  name: 'RedirCard',
  mixins: [clientConfigs],
  props: ['content', 'widthElement', 'maxElements'],
  directives: {
		LazyLoading
  },
  computed: {
    whatConfig: function(){
      // if ( this.ClientDevice != 'desktop' || this.ClientWidth == 'mobileWidth'){
      if ( this.ClientDevice == 'mobile' || this.ClientWidth == 'mobileWidth'){
        return 'RedirCard-mobile'
      }else {
        return ''
      }
    },


  },
  created(){
    // console.log(this.content)
  }
}
</script>

<style lang="scss">
@import '@/css/variables';

.RedirCard{
	position: relative;
	width: 100%;
	height: 100%;	
	// background-color: var(--article-color-lighten);
  overflow: hidden;
  cursor: pointer;

  display: flex;

  &--element{
    margin-bottom: var(--esp-big);

    &--cover{     
      overflow: hidden;
      // width: 100%;
      // height: 20vw;

      &--img{
        width: 100%;  
        height: 100%;
        position: relative;
        object-fit: cover;
      }
    }

    &--title{
      font-size: var(--card-articles-font-title); // --card-article--font--title
      line-height: 0.9;
          
      // margin-top: 0.6vw;
      margin-top: calc(0.25 * var(--card-articles-font-text));


      overflow-wrap: anywhere;
      @supports ( hyphens: auto ) {
        hyphens: auto;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
      }
    }
    &--text{
      font-family: 'vd-reg';
      font-size: var(--card-articles-font-text);  // --card-article--font--text
      line-height: 1;

      // margin-top: 0.3vw;
      // margin-top: var(--esp-small);
      margin-top: calc(0.35 * var(--card-articles-font-text));

      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;  
      overflow: hidden;

      & em {
        font-family: 'vd-ita';
      }
    }
  }
}






.one-columns{
  width: 100%;
}

.two-columns{
  // width: 50%;
  width: calc( ( 100% - (3 * var(--esp-small) ) ) / 2 );  // --card-article--title--marge
  margin-left: var(--esp-small);
}

.four-columns{
  width: calc( ( 100% - (5 * var(--esp-small)) ) / 4 ); // --card-article--esp
  margin-left: var(--esp-small);
}

.five-columns{
  width: calc( ( 100% - (6 * var(--esp-small)) ) / 5 ); // --card-article--esp
  margin-left: var(--esp-small);
}

.six-columns{
  width: calc( ( 100% - (7 * var(--esp-small)) ) / 6 ); // --card-article--esp
  margin-left: var(--esp-small);
}

</style>