<template>
  <div class="MainVideo">

    <transition name="fade-main-video" mode="out-in">

    <div class="MainVideo--poster-container"
    v-if="!videoStarted" 
    :style="{'height': iframe_height() + 'px'}"
    @click="playVideo"
    >

      <div class="MainVideo--poster-container--button"
      v-if="!videoStarted" 
      :style="{'top': iframe_height() / 2 + 'px'}"
      ></div>

      <img
      class="MainVideo--poster-container--poster"
      :srcset="content.poster"
      :style="{'height': iframe_height() + 'px'}"
      >

    </div>

    </transition>

    <div class="wrap"
    :style="{'opacity': videoStarted == true ? 1:0}"
    >
      <iframe 
      id="ytplayer" 
      type="text/html" 

      :width="iframe_width" 
      :height="iframe_height"
      
      :style="{'height': iframe_height() + 'px'}"
      :src="link_video"

      frameborder="0"
      allowfullscreen/>
    </div>


  </div>
</template>

<script>
import clientConfigs from '@/mixins/clientConfigs.js'

export default {
  name: 'MainVideo',
  mixins: [clientConfigs],
  props: ['content'],
  data: function(){
    return {
      videoStarted: false
    }
  },
  computed: {
    link_video: function(){
      return 'https://www.youtube.com/embed/'+ this.content.id + '?rel=0&controls=1&showinfo=0&modestbranding=1&enablejsapi=1&loop=1'
      // return 'https://www.youtube.com/embed/'+ this.content.id + '?rel=0&controls=1&showinfo=0&modestbranding=1&enablejsapi=1&loop=1'
    },
    whatVideoFormat: function(){
      if (this.content.format.width && this.content.format.height){
        return this.content.format.height / this.content.format.width
      }else {
        return 1080 / 1920
      }
    }
  },
  methods:{
    playVideo: function(ev){
      document.querySelector('#ytplayer').src += "&autoplay=1";
      ev.preventDefault();
      this.videoStarted = true
    },
    iframe_width: function(){
      let espArticleRaw = window.getComputedStyle(document.documentElement).getPropertyValue('--esp-med');
      let espArticle = Number(espArticleRaw.split('vw')[0])
      let margeArticleRaw = window.getComputedStyle(document.documentElement).getPropertyValue('--marge-article');
      let margeArticle = this.ClientFormat == 'landscape' && this.ClientDevice == 'desktop' ? Number(margeArticleRaw.split('vw')[0]) : 0

      return window.innerWidth - (2 * espArticle * window.innerWidth / 100) - (margeArticle * window.innerWidth / 100)
    },
    iframe_height: function(){
      // let height = this.iframe_width() * 1080 / 1920
      let height = this.iframe_width() * this.whatVideoFormat
      // console.log('height', height)

      let espArticleRaw = window.getComputedStyle(document.documentElement).getPropertyValue('--esp-med');
      let espArticle = Number(espArticleRaw.split('vw')[0])
      let margeArticleRaw = window.getComputedStyle(document.documentElement).getPropertyValue('--marge-article');
      let margeArticle = this.ClientFormat == 'landscape' && this.ClientDevice == 'desktop' ? Number(margeArticleRaw.split('vw')[0]) : 0

      let maxVideoHeight = window.innerHeight - (2 * espArticle * window.innerWidth / 100) - (margeArticle * window.innerWidth / 100)

      if (height > maxVideoHeight){
        // console.log('iframe_height maxVideoHeight')
        return maxVideoHeight
      }else {
        // console.log('iframe_height height')
        return height
      }
    },
    sendHeight(dimension){
      this.$emit('send-height', dimension)
    },
  },

	mounted(){
    this.$tools.setResizeObserver(this.$el, () => {
      this.$forceUpdate();
      this.sendHeight(this.$el.getBoundingClientRect().height)
    })
  }
}
</script>

<style lang="scss">
@import '@/css/variables';

.fade-main-video-enter-active, .fade-main-video-leave-active {
  transition: opacity 2s;
  pointer-events: none;
}
.fade-main-video-enter, .fade-main-video-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  pointer-events: none;
}

.MainVideo{

	height: calc(100vh - var(--marge-article));
	width: 100%;

	background-color: var(--article-color-lighten);

}


.wrap{
  width: calc(100% - (2 * var(--esp-med)));
  margin-left: var(--esp-med);
  margin-right: var(--esp-med);

  height: 100%;

  overflow: hidden;
  opacity: 0;
  transition: opacity 2s;

  overflow: hidden;

  & iframe{

    width: 100%;
    margin-top: var(--esp-med);
    transform: scale( 1.01);
  }

}

.MainVideo--poster-container{
  position: absolute;
  width: calc(100% - (2 * var(--esp-med)));
  margin-left: var(--esp-med);
  margin-right: var(--esp-med);
  overflow: hidden;

  height: 100%;
  z-index: 10;

  cursor: pointer;

  margin-top: var(--esp-med);

  &--button{
    position: absolute;
    width: 50px;
    height: 50px;

    left: 50%;
    // transform: translate(-50%,-25%);
    transform: translate(-50%,-50%);
    z-index: 11;

    width: 0;
    height: 0;

    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 100px solid var(--article-color-lighten);
  }

  &--poster{
    width: 100%;
    transform: scale( 1.01);
    object-fit: cover;
    // height: 100%;
  }

}

</style>

    

