<template>

  <div class="viewer-wrapper">
    <div class="viewer-wrapper--sticky-container">
      <div class="viewer-wrapper--sticky-container--sticky">


				<div class="MainViewer" :style="whatStyle">
					<div class="MainViewer--container" ref="container">

						<div class="MainViewer--container--thumbs" @mouseenter.stop="viewerMouseenter()" @mouseleave.stop="viewerMouseleave()">

							<img class="MainViewer--container--thumbs--element" 
							v-for="(image, index) in content" 
							:key="image.filename" 
							:src="image.url"
							:srcset="image.srcset"
							:data-legende="image.legende" 
							sizes="13vw"
							@mouseenter="thumbMouseenter(index)">

						</div>

						<transition name="fade" appear mode="out-in">
						<div v-if="viewerisVisible" class="MainViewer--container--viewer">

							<MainViewerImage :thumb="viewerThumb" :content="viewerUrl" :orientation="viewerOrientation" ></MainViewerImage>
							<div class="MainViewer--container--viewer--legende" v-html="viewerLegende"></div>

						</div>
						</transition>

					</div>
				</div>


      </div>
    </div>
  </div>

</template>

<script>
import MainViewerImage from '@/components/article/main/MainViewerImage.vue'

// import Scrollbar from '@/components/scrollbar/Scrollbar.vue'

export default {
  name: 'MainViewer',
  props: ['content', 'articleType'],
  components: {
		MainViewerImage,

		// Scrollbar
  },
  data: function () {
    return {
      viewerisVisible: false,
      viewerUrl: '',
      viewerThumb: '',
      viewerLegende: '',
      viewerOrientation: '',

      DOM_ViewArticle: '',
      oldScrollTop: ''
    }
  },

  computed: {
		whatStyle: function(){
			return ''
		}
  },

  methods: {
		viewerMouseenter: function(){
			this.viewerisVisible = true

			// setTimeout(() => { this.$refs.container.style.overflow = 'scroll' }, 100);
			// this.$refs.container.style.overflow = 'scroll'

			/* prevent ViewArticle from scrolling - à vérifier */
			this.DOM_ViewArticle.style.overflow = 'hidden'
			this.oldScrollTop = this.DOM_ViewArticle.scrollTop
		},
		viewerMouseleave: function(){
			// console.log('mouseleave')
			this.viewerisVisible = false

			this.viewerUrl = ''
			this.viewerThumb = ''


			// this.$refs.container.style.overflow = 'hidden'

			/* prevent ViewArticle from scrolling - à vérifier */
			this.DOM_ViewArticle.style.overflow = 'scroll'
			this.DOM_ViewArticle.scrollTop = this.oldScrollTop
		},
		thumbMouseenter: function(index){
			this.viewerThumb = this.content[index].thumb
			this.viewerUrl = this.content[index].srcset
			this.viewerLegende = this.content[index].legende
			this.viewerOrientation = this.content[index].orientation
		}
  },
	mounted(){

		if (this.articleType == 'texteImagesWithVideo'){
			this.DOM_ViewArticle = this.$el.parentNode.parentNode.parentNode.parentNode
			
		}else {
			this.DOM_ViewArticle = this.$el.parentNode.parentNode.parentNode
		}
	}
}
</script>

<style lang="scss">
@import '@/css/variables';


.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.viewer-wrapper{
  display: flex;
  flex-direction: column;

  // background-color: red;

  &--sticky-container{
    flex-grow:1;
    flex-shrink: 1;
    flex-basis: auto;

    &--sticky{
      position: sticky;
      top: 0;
      height: calc(100vh - var(--marge-article));
    }
  }
}

.MainViewer{
	width: var(--article-thumbs-width);
	height: calc(100vh - var(--marge-article));

	position : relative;
}

.MainViewer--container{

	width: 100%;
	height: calc(100vh - var(--marge-article));
	
	// en cours
	background-color: var(--article-color-lighten);
	overflow: scroll;

	scrollbar-width: none;

	&--thumbs{

		width: 100%;
		display: flex;
		flex-wrap: wrap;

		flex-direction: column;

		&--element{
			width: 100%;
		}
	}

	&--viewer{
		height: calc(100vh - var(--marge-article));
		width: var(--article-viewer-width);

		position: absolute;
		right: var(--article-thumbs-width);

		top: 0;
	}
}

.MainViewer--container--viewer{
	display: flex;
	flex-wrap: nowrap;
	background-color: var(--article-color-lighten);

	&--legende{
		width: 25%;
		height: 100%;
		padding: var(--esp-med);

		font-family: 'vd-reg';
		font-size: var(--article-font-legende);
		line-height: 1.2;

		text-align: center;

		& em{
			font-family: 'vd-ita';
		}
	}
}

</style>