<template>
  <div class="MainArticle" @click="scrollClick" @mousemove="testMousemove" @mouseenter="testMouseEnter">

    <div class="MainArticle--container" v-if="articleType == 'texte'">
      <MainTexte :content="{text: content.text, int_text: content.int_text, notes: content.notes, rubrique: content.rubrique}" :scrollSequence="scrollSequence"
      ></MainTexte>
    </div>

    <div class="MainArticle--container" v-if="articleType == 'texteImages'">
      <MainTexte :content="{text: content.text, int_text: content.int_text, notes: content.notes, rubrique: content.rubrique}" :scrollSequence="scrollSequence"
      ></MainTexte>
      <!-- <MainViewer :content="content.gallery" :scrollSequence="scrollSequence"></MainViewer> -->
      <MainViewer :content="content.gallery"></MainViewer>
    </div>

    <div v-if="articleType == 'texteImagesWithVideo'">
      <MainVideo @send-height="sendMainVideoHeight" :content="content.video"></MainVideo>
      <div class="MainArticle--container">
        <MainTexte :content="{text: content.text, int_text: content.int_text, notes: content.notes, rubrique: content.rubrique}" :scrollSequence="scrollSequence"></MainTexte>
        <MainViewer :content="content.gallery" :articleType="'texteImagesWithVideo'" ></MainViewer>
      </div>
    </div>

    <div class="MainArticle--container" v-if="articleType == 'video'">
      <MainVideo @send-height="sendMainVideoHeight" :content="content.video"></MainVideo> 
    </div>

    <div v-if="articleType == 'itw'">
      <MainItw :content="{text: content.text, cover: content.cover, rubrique: content.rubrique}" :scrollSequence="scrollSequence"></MainItw>
    </div>

    <div class="MainArticle--container" v-if="articleType == 'images'">
      <MainImg 
      :content="content.gallery" 
      :scrollSequence="scrollSequence" 
      :scrollMainImg="scrollMainImg" 
      :eventClick="eventClick" 
      @send-height="setHeight"

      :clientX="clientX" 
      :clientY="clientY"
      ></MainImg>
    </div>


  </div>
</template>

<script>
import MainTexte from '@/components/article/main/MainTexte.vue'
import MainViewer from '@/components/article/main/MainViewer4.vue'
import MainImg from '@/components/article/main/MainImg5.vue'
import MainVideo from '@/components/article/main/MainVideo.vue'
import MainItw from '@/components/article/main/MainItw.vue'

export default {
  name: 'MainArticle',
  props: ['articleType', 'content', 'scrollSequence', 'scrollMainImg'],
  data: function () {
    return {
      eventClick: {},

      clientX: '',
      clientY: ''
    }
  },
  components: {
    MainTexte,
    MainViewer,
    MainImg,
    MainVideo,
    MainItw
  },
  // watch:{
  //   scrollSequence: function(val){
  //     console.log(val)
  //   }
  // },
  computed: {

  },
  methods: {
    testMouseEnter: function(e){ // gere problème cursor à l'ouverture (clientX undifined si mousemove non activé)
      this.clientX = e.clientX
      this.clientY = e.clientY   
    },
    testMousemove: function(e){
      this.clientX = e.clientX
      this.clientY = e.clientY

      // console.log('testMousemove')
      // this.eventClick = {
      //   clientX: e.clientX,
      //   clientY: e.clientY
      // }
      
    },
    scrollClick(e){
      this.eventClick = {
        clientX: e.clientX,
        clientY: e.clientY
      }
    },
    sendScrollData(){
      this.$emit('send-scroll-data', this.$el.getBoundingClientRect())
    },
    sendMainVideoHeight(dimension){
      this.$emit('send-mainvideo-height', dimension)
    },
    setHeight(height){
      this.$el.style.height = height + 400 + 'px'
      this.$emit('send-scroll-data', this.$el.getBoundingClientRect())
    },
  },

  mounted () {
    this.$tools.setResizeObserver(this.$el, () => {
      this.sendScrollData()
    })

    // console.log(this.content)
  },
}
</script>

<style lang="scss">
@import '@/css/variables';

.MainArticle--container{
	display: flex;
	// overflow: hidden;
	
	height: auto;
  // height: 5020px; 
  // 
  justify-content: space-between; 
}

.container-video{
  position: relative;
}

.MainArticle{
  // cursor: '';
  // cursor: pointer;
}



</style>
